import store from '../../store'

export default [
    {
        title: 'Place Order',
        icon: 'ClipboardIcon',
        route: 'place-order',
    },
    {
        title: 'Confirm Order',
        icon: 'CheckSquareIcon',
        route: 'confirm-order',
    },
    {
        title: 'Order History',
        icon: 'ClipboardIcon',
        route: 'client-orders-database',
    },
    {
        title: 'Change Password',
        icon: 'UnlockIcon',
        route: 'client-change-password',
    },
]
