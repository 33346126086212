import store from '../../store'

export default [
    {
        title: 'Dashboard',
        icon: 'LayoutIcon',
        route: 'admin-dashboard',
    },
    {
        title: 'Manage Order',
        icon: 'HomeIcon',
        route: 'manage-order',
    },
    {
        title: 'Summary',
        icon: 'FileTextIcon',
        route: 'order-summary',
    },
    {
        title: 'Order List',
        icon: 'ClipboardIcon',
        route: 'order-database',
    },
    {
        title: 'User List',
        icon: 'UsersIcon',
        route: 'user-database',
    },
    {
        title: 'Item List',
        icon: 'Edit3Icon',
        route: 'item-database',
    },
    /*{
        title: 'Settings',
        icon: 'SettingsIcon',
        route: 'settings-database',
    },*/
]
