<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ name | capitalize }}
        </p>
        <span class="user-status">{{ account_type | capitalize }}</span>
      </div>
      <b-avatar
        size="40"
        :src="img"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="0"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <!-- <b-dropdown-divider /> -->

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50"/>
      <span>Logout</span>
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import { POST_API,LOGOUT,SET_PROFILE_DATA } from "../../../../../store/actions.type"
import store from "../../../../../store"
import Bus from "../../../../../event-bus";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      img:'',
      name: this.$store.getters.currentUser.name,
      account_type: this.$store.getters.currentUser.account_type,
      path: '/client/view-profile'
    }
  },
  methods: {
    logout() {
      
      var account_type = store.getters.currentUser.account_type;
      var id = store.getters.currentUser._id;

        var routeName = 'login'

      this.$store.dispatch(LOGOUT, {
          data: {
          },
          api: '/api/logout?id='+id
      })
      .then(() => {
          if (!this.$store.getters.containsErrors) {
              this.$router.push({ name: routeName })
          }
      });
    },

    CompanyInfo() {

      this.$store.dispatch(POST_API, {
          data: {
            id: this.$store.getters.currentUser.organization
          },
          api: '/api/client-detail'
      })
      .then(() => {
          var data = this.$store.getters.getResults.data;
          this.img = data.company_logo_thumbnail;
          //console.log(data,this.img)
      });
    },

    updateProfile() {

      this.$store.dispatch(SET_PROFILE_DATA, {
          data: {
            id: this.$store.getters.currentUser._id
          },
          api: '/api/reset-profile'
      })
      .then(() => {
          var data = this.$store.getters.getResults;
          //console.log(this.$store.getters.currentUser);
          this.userName = this.$store.getters.currentUser.full_name;
          this.role = this.$store.getters.currentUser.role;
          if (this.$store.getters.currentUser.role != 'super_admin') {
              this.CompanyInfo();
          }else {
              this.img = this.$store.getters.currentUser.profile_thumbnail;
          }
          
          //console.log(data,this.img)
      });
    },

    /*StaffInfo() {

      this.$store.dispatch(POST_API, {
          data: {
            id: this.$store.getters.currentUser._id
          },
          api: '/api/edit-staff'
      })
      .then(() => {
          var data = this.$store.getters.getResults.data;
          this.img = data.profile_thumbnail;
          //console.log(data,this.img)
      });
    },*/
  },
  mounted(){
    if (this.$store.getters.currentUser.role != 'super_admin') {
        //this.CompanyInfo();
    }else {
        this.img = this.$store.getters.currentUser.profile_thumbnail;
    }

    //if(this.role == 'site_client' || this.role == 'sub_site_client' || this.role == 'crew'){
      //this.path = '/site/view-profile';
    //} else {
      this.path = '/client/view-profile';
    //}

    Bus.$on('update-profile', () => {

        this.updateProfile();
        //console.log('aya kuch')
    })
  }
}
</script>
