/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import store from '../../store'
import admindashboard from './admindashboard'

import clientdashboard from './clientdashboard'
import emptydashboard from './emptydashboard';

var item = [];

if (store.getters.currentUser == null) {
	item = emptydashboard;
}
else if (store.getters.currentUser.account_type == 'Admin') {
	item = admindashboard;
}else if(store.getters.currentUser.account_type == 'Customer') {
	item = clientdashboard;
	//
}

/*function allowedMenus() {
	var permissions = [];

	store.getters.currentUser.menu_permission.forEach(menu => {
		if(menu.access == true){

			var p_permission = [];

			// parent menu
			if(menu.permissions){
				menu.permissions.forEach(p_menu => {
					if(p_menu.access == true){
						p_permission.push(p_menu);
					}
				});
				menu.permissions = p_permission;
			}

			// child menu
			if(menu.children){
				menu.children.forEach(child => {
					if(child.access == true){

						// child menu permission
						var ch_access = [];
						child.permissions.forEach(c => {
							if(c.access == true){
								ch_access.push(c);
							}
						});
						child.permissions = ch_access;

						p_permission.push(child);
					}
				});
				menu.children = p_permission;
			}

			permissions.push(menu);
		}
	});
	return permissions;
}*/

export default [
...item
]


